'use client';

import React, { createContext, useContext } from 'react';

import * as hooks from '@personly/libs-hooks';

const ValidatorsContext = createContext(null);

interface Props {
  children: React.JSX.Element;
  validators: any;
}

const ValidatorsProvider = ({ children, validators }: Props) => {
  const value = hooks.useValidators(validators);

  return (
    <ValidatorsContext.Provider value={value}>
      {children}
    </ValidatorsContext.Provider>
  );
};

function useValidators() {
  const context = useContext(ValidatorsContext);
  if (!context) throw new Error('useValidators requires a context!');

  return context;
}

export { ValidatorsProvider, useValidators };
