var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
const Input = (_a, ref) => {
    var { invalid = false, role = 'input', disabled = false } = _a, props = __rest(_a, ["invalid", "role", "disabled"]);
    const classNames = [
        'text-input-text-color',
        'block',
        'w-full',
        'h-10',
        'py-1.5',
        'px-2.5',
        'bg-clip-padding',
        'rounded',
        'border',
        'border-input-border-color',
        'focus:outline-none',
        'focus:border',
        'focus:border-input-focus-color',
        'focus:bg-input-background-color',
        'focus:text-input-focus-color',
        'placeholder:text-input-placeholder-text-color',
    ];
    if (disabled) {
        classNames.push('opacity-60');
        classNames.push('outline-none');
    }
    if (invalid) {
        classNames.push('!border-input-border-invalid-color');
        classNames.push('focus:text-input-text-color');
        classNames.push('focus:border-input-border-invalid-color)');
    }
    if (role === 'card') {
        classNames.push('bg-card-background-color');
        classNames.push('focus:!bg-card-background-color');
        if (invalid) {
            classNames.push('focus:!bg-card-background-color');
        }
    }
    else if (role === 'input') {
        classNames.push('bg-input-background-color');
        classNames.push('focus:!bg-input-background-color');
        if (invalid) {
            classNames.push('focus:!bg-input-background-color');
        }
    }
    return (React.createElement("input", Object.assign({ readOnly: disabled, autoComplete: "off", ref: ref, className: classNames.join(' '), disabled: disabled }, props)));
};
Input.displayName = 'Input';
const ForwardedInput = forwardRef(Input);
export default ForwardedInput;
