'use client';

import React from 'react';

import { Button } from '@personly/libs-widgets';

import { useLoginForm } from '../../forms/login-form';

interface Props {
  children: string;
}

const LoginSubmitButton = ({ children }: Props) => {
  const { isDisabled } = useLoginForm();

  return (
    <Button block type="submit" disabled={isDisabled}>
      {children}
    </Button>
  );
};

export default LoginSubmitButton;
