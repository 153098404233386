'use client';

import React, { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import { useNotifications } from '@personly/libs-providers';

interface Props {
  children?: React.JSX.Element;
}

const Provider = ({ children }: Props) => {
  const searchParams = useSearchParams();
  const { createNotification } = useNotifications();

  useEffect(() => {
    if (!searchParams.has('code')) return;
    createNotification('ERROR', searchParams.get('code'));
  }, [createNotification, searchParams]);

  return children;
};

export default Provider;
