'use client';

import { useEffect, useRef } from 'react';

import { Input } from '@personly/libs-widgets';

import { useLoginForm } from '../../forms/login-form';

interface Props {
  id: string;
  placeholder: string;
}

const LoginEmailInput = ({ id, placeholder }: Props) => {
  const { email, setEmail } = useLoginForm();
  const usernameRef = useRef(null);

  useEffect(() => {
    if (usernameRef) usernameRef.current.focus();
  }, []);

  return (
    <Input
      ref={usernameRef}
      type="email"
      placeholder={placeholder}
      name="username"
      id={id}
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  );
};

export default LoginEmailInput;
