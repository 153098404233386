var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { Icon } from '../';
const InputIcon = (_a, ref) => {
    var { invalid = false, role = 'input', disabled = false, icon, label, onClick } = _a, props = __rest(_a, ["invalid", "role", "disabled", "icon", "label", "onClick"]);
    const inputClassNames = [
        'text-input-text-color',
        'block',
        'h-10',
        'py-1.5',
        'px-2.5',
        'bg-clip-padding',
        'rounded-tl',
        'rounded-bl',
        'border-l',
        'border-y',
        'border-input-border-color',
        'focus:outline-none',
        'placeholder:text-input-placeholder-text-color',
        'relative',
        'flex-auto',
        'min-w-0',
    ];
    if (disabled) {
        inputClassNames.push('opacity-60');
        inputClassNames.push('outline-none');
    }
    if (invalid) {
        inputClassNames.push('!border-input-border-invalid-color');
        inputClassNames.push('focus:text-input-text-color');
        inputClassNames.push('focus:border-input-border-invalid-color)');
    }
    if (role === 'card') {
        inputClassNames.push('bg-card-background-color');
        inputClassNames.push('focus:!bg-card-background-color');
        if (invalid) {
            inputClassNames.push('focus:!bg-card-background-color');
        }
    }
    else if (role === 'input') {
        inputClassNames.push('bg-input-background-color');
        inputClassNames.push('focus:!bg-input-background-color');
        if (invalid) {
            inputClassNames.push('focus:!bg-input-background-color');
        }
    }
    else {
        inputClassNames.push('bg-input-background-color');
    }
    const classNames = [
        'relative',
        'flex',
        'flex-wrap',
        'items-stretch',
        'w-full',
    ];
    if (role === 'card')
        classNames.push('bg-card-background-color');
    else if (role === 'input')
        classNames.push('bg-input-background-color');
    const buttonClassnames = [
        'rounded-tr',
        'rounded-br',
        'border-r',
        'border-y',
        'border-input-border-color',
        'text-input-icon-color',
        'px-3',
        'focus:outline-none',
    ];
    if (role === 'card') {
        buttonClassnames.push('bg-card-background-color');
    }
    else if (role === 'input') {
        buttonClassnames.push('bg-input-background-color');
    }
    else {
        buttonClassnames.push('bg-input-background-color');
    }
    if (invalid) {
        buttonClassnames.push('!border-input-border-invalid-color');
    }
    return (React.createElement("div", { className: classNames.join(' ') },
        React.createElement("input", Object.assign({ readOnly: disabled, autoComplete: "off", ref: ref, className: inputClassNames.join(' '), disabled: disabled }, props)),
        React.createElement("button", { "aria-label": label, type: "button", onClick: () => onClick(), className: buttonClassnames.join(' ') },
            React.createElement(Icon, { icon: icon, type: "solid", size: "1x" }))));
};
InputIcon.displayName = 'InputIcon';
const ForwardedInputIcon = forwardRef(InputIcon);
export default ForwardedInputIcon;
