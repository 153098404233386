'use client';

import { useState } from 'react';

import { InputIcon } from '@personly/libs-widgets';
import { visibilityIcon } from '@personly/libs-icons';

import { useLoginForm } from '../../forms/login-form';

interface Props {
  id: string;
  placeholder: string;
}

const LoginPasswordInput = ({ id, placeholder }: Props) => {
  const { password, setPassword } = useLoginForm();
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <InputIcon
      icon={visibilityIcon(passwordVisible)}
      id={id}
      label="Password Visibility"
      name="password"
      onChange={(e) => setPassword(e.target.value)}
      onClick={() => setPasswordVisible(!passwordVisible)}
      placeholder={placeholder}
      type={passwordVisible ? 'text' : 'password'}
      value={password}
    />
  );
};

export default LoginPasswordInput;
