'use client';

import React, { createContext, useContext, useMemo, useState } from 'react';

import { Form } from '@personly/libs-widgets';

const LoginFormContext = createContext(null);

interface Props {
  children: React.JSX.Element | React.JSX.Element[];
}

const LoginForm = ({ children }: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isDisabled = useMemo(
    () => email.length === 0 || password.length === 0,
    [email.length, password.length]
  );

  const value = { isDisabled, email, setEmail, password, setPassword };

  return (
    <Form method="post" action="/agon/v1/guest/verifyAccount">
      <LoginFormContext.Provider value={value}>
        {children}
      </LoginFormContext.Provider>
    </Form>
  );
};

function useLoginForm() {
  const context = useContext(LoginFormContext);
  if (!context) throw new Error('useLoginForm requires a context!');

  return context;
}

export { LoginForm, useLoginForm };
