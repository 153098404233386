'use client';

import React from 'react';

import { useCookie, useTld } from '@personly/libs-hooks';
import { SchemesProvider } from '@personly/libs-providers';
import { deploymentUrl } from '@personly/libs-urls';

const { host } = deploymentUrl();

interface Props {
  children: React.JSX.Element;
  schemes: any;
}

const Provider = ({ children, schemes }: Props) => {
  const cookie = useCookie();
  const tld = useTld();

  return (
    <SchemesProvider cookie={cookie} host={host} schemes={schemes} tld={tld}>
      {children}
    </SchemesProvider>
  );
};

export default Provider;
