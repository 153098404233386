'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';

import { useSearchParams } from 'next/navigation';

const StepsContext = createContext(null);

interface Props {
  children: React.JSX.Element;
}

const StepsProvider = ({ children }: Props) => {
  const [step, setStep] = useState(1);
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!searchParams.has('step')) return;
    const queryStep = parseInt(searchParams.get('step'), 10);
    if ([1, 2, 3].includes(queryStep)) setStep(queryStep);
  }, [searchParams, setStep]);

  const value = { step, setStep };

  return (
    <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
  );
};

function useSteps() {
  const context = useContext(StepsContext);
  if (!context) throw new Error('useSteps requires a context!');

  return context;
}

export { StepsProvider, useSteps };
